<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :visible.sync="infoVisible"
    title="完善信息"
    width="400px"
    append-to-body
    center
    class="info-modal"
  >
    <el-form
      size="medium"
      class="form-content"
      key="reset"
      center
      :rules="resetRules"
      :model="resetForm"
      ref="resetForm"
    >
      <template v-if="needPhone">
        <el-form-item prop="phone">
          <el-input
            placeholder="请输入手机号码"
            v-model="resetForm.phone"
            maxlength="11"
          >
            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
          </el-input>
        </el-form-item>
        <div class="code-wrapper align-top">
          <el-form-item prop="code">
            <el-input
              placeholder="请输入验证码"
              class="w-180"
              v-model="resetForm.code"
            >
              <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
            </el-input>
          </el-form-item>
          <el-button
            class="code-btn"
            @click="handleCode('login', 'resetForm')"
            :disabled="
              !resetForm.phone ||
              !/^1[3-9]\d{9}$/.test(resetForm.phone) ||
              !!timer
            "
            >{{ tips }}</el-button
          >
        </div>
      </template>
      <template v-if="needPwd">
        <el-form-item prop="pwd">
          <el-input
            type="password"
            placeholder="请输入密码"
            show-password
            v-model="resetForm.pwd"
          >
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="repwd">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            show-password
            v-model="resetForm.repwd"
          >
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button
          class="block"
          type="primary"
          size="medium"
          @click="handleInfo"
          >确认修改</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { setWxLoginCallback, resetLogin, getCode,bindingWeChat } from "@/api";
export default {
  data() {
    let validRp = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetForm.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      codeIds: {
        login: 1508230,
        reset: 1508663,
        register: 1508230,
      },
      type: "login",
      tips: "获取手机验证码",
      timer: null,
      time: 60,
      infoVisible: false,
      resetForm: {
        phone: "",
        code: "",
        pwd: "",
        repwd: "",
      },
      resetRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          // {
          //   pattern: /^1[3-9]\d{9}$/,
          //   message: '手机号格式错误',
          //   trigger: 'blur'
          // }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repwd: [{ validator: validRp, trigger: "blur" }],
      },
      needPhone: false,
      needPwd: false,
    };
  },
  created() {
    this.$store.commit("triggerLogin", false);
    let storeCode = sessionStorage.getItem('code')
    // console.log(this.$route)
    const { code } = this.$route.query;
    if (!code || storeCode === code) {
      this.$router.replace({
        path: "/",
      });
    } else {
      if (this.$route.query.codeType === 'bind') {
        sessionStorage.setItem('code', code)
        bindingWeChat({
          code,
        }).then(async () => {
          this.$message.success('绑定成功');
          await this.$store.dispatch("getUserInfo");
          this.$router.replace({
            path: "/member/index",
          })
        })
        return
      }
      sessionStorage.setItem('code', code)
      setWxLoginCallback({
        code,
      }).then((res) => {
        if (!res.PassWord || !res.phone) {
          this.needPhone = !res.phone;
          this.needPwd = !res.PassWord;

          sessionStorage.setItem("token", res.t);
          // this.dialogTableVisible = false;
          this.infoVisible = true;
        } else {
          localStorage.setItem("token", res.t);
          this.$message.success("登录成功");
          this.$router.replace({
            path: "/",
          });
        }
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    async handleCode(type, form = "loginForm") {
      if (this.timer) return;
      await getCode({
        phone: this[form].phone,
        id: this.codeIds[type],
      });
      this.$message.success("验证码发送成功");
      this.sendCode = true;
      this.timer = setInterval(() => {
        this.time -= 1;
        this.tips = `${this.time}秒后重新获取`;
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.time = 60;
          this.tips = "获取手机验证码";
        }
      }, 1000);
    },
    handleInfo() {
      this.$refs["resetForm"].validate(async (valid) => {
        if (valid) {
          if (!this.sendCode && this.needPhone) {
            this.$message.warning("请获取验证码!");
            return;
          }
          await resetLogin({
            phone: this.needPhone ? this.resetForm.phone : undefined,
            code: this.needPhone ? this.resetForm.code : undefined,
            pwd: this.needPwd ? this.resetForm.pwd : undefined,
            repwd: this.needPwd ? this.resetForm.repwd : undefined,
          });
          this.$message.success("设置成功");
          localStorage.setItem("token", sessionStorage.getItem("token"));
          sessionStorage.removeItem("token");
          this.$router.replace({
            path: "/",
          });
        } else {
          this.$message.warning("请认真填写内容!");
          return false;
        }
      });
    },
  },
};
</script>
